$primary: #1fa32f;
@import '../../node_modules/bulma/bulma.sass';
@import '../../node_modules/bulma-extensions/bulma-pageloader/src/sass/index';
@import '../../node_modules/bulma-extensions/bulma-steps/src/sass/index';
@import '../../node_modules/bulma-extensions/bulma-divider/src/sass/index';
@import '../../node_modules/bulma-extensions/bulma-checkradio/src/sass/index';

@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&family=Oswald&family=Raleway&family=Roboto&display=swap');

html, body, #root {
  height: auto;
  width: auto;
}

body {
  background-color: #bdc3c7;
}

label.radio input {
  margin-right: 5px;
}

.field-label .label {
  min-width: 140px;
}

label {
  font-size: 14px !important;
  line-height: 1rem !important;
  padding-top: 0 !important;
}

.live-flex {
  @media only screen and (max-width: 1000px) {
    width: 100% !important;
  }
}

@keyframes width0to100 {
  0% {
    width: 0;
  }
  100% {
    width: '100%';
  }
}

.ant-form-item {
  margin-bottom: 10px;
}

strong {
  color: inherit;
}

img {
  max-width: inherit;
}

// .ant-select-selection-placeholder {
//   color:black;
// }

[disabled].input {
  color: black;
  background-color: white;
  border-color: white;
}

@media screen and (min-width: 769px), print {
  .field-label {
    flex-grow: 2;
  }

  .field-body {
    flex-grow: 3;
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideFromLeft 0.5s forwards;
}

.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.85);
  cursor: not-allowed;
}